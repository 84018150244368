import styled from 'styled-components'

export const BackToTopContainer = styled.img<{
    display?: string
}>`
    display: ${props => props.display ? props.display : 'none'};
    position: fixed;
    bottom: 160px;
    right: 40px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.3);
    }
    `
    
import styled from 'styled-components'


export const Logo = styled.img<{
    bottom: string,
}>`
    bottom: ${props => props.bottom};
    display: block;
    position: fixed;
    right: 40px;    
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.3);
    `
import React, { useState } from 'react'
import { Link } from "gatsby"
import * as s from './socialButton.styled'
import logoFb from '../../../../static/fb_2.png'    //before hover
import logoFb2 from '../../../../static/fb_1.png'   //after mouse hover
import logoIg from '../../../../static/instagram_2.png'     //before hover
import logoIg2 from '../../../../static/instagram_1.png'    //after mouse hover


const SocialButton = () => {

    const [FbImg, setFbImg] = useState(logoFb2)
    const [IgImg, setIgImg] = useState(logoIg2)

    return (
        <>
            <a href="https://www.facebook.com/Magusoft" target="_blank">
                <s.Logo onMouseEnter={() => setFbImg(logoFb)} src={FbImg} onMouseLeave={() => setFbImg(logoFb2)} bottom={"40px"} />
            </a>

            <a href="https://www.instagram.com/Magusoft" target="_blank">
                <s.Logo onMouseEnter={() => setIgImg(logoIg)} src={IgImg} onMouseLeave={() => setIgImg(logoIg2)} bottom={"100px"} />
            </a>
        </>

    )
}

export default SocialButton
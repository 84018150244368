import * as s from '../HOC/defaultLayer.styled'

import BackToTop from '../components/Layout/backToTop/backToTop'
import PageFooter from '../components/Layout/pageFooter/pageFooter'
import PageHeader from '../components/Layout/pageHeader/pageHeader'
import React from 'react'
import SocialButton from '../components/Layout/socialButton/socialButton'

export default function withDefaultLayout (WrappedComponent: any) {
    return class extends React.Component {

        componentDidMount(){
            document.title = "Magusoft"
          }

        render(){
            return(
                <s.MainContainer>
                    <s.PageContainer>
                        <WrappedComponent {...this.props}/>
                    </s.PageContainer>
                    <SocialButton />
                    <BackToTop />
                    <PageFooter />
                </s.MainContainer>
            )
        }
    }
}

import * as s from './pageFooter.styled'

import React from 'react'

const PageFooter = () =>{
    return(
        <s.FooterContainer>
            &copy; Magusoft 2020 - {new Date().getFullYear()}
        </s.FooterContainer>
    )
}

export default PageFooter

import * as s from '../styles/index.styled'

import React from "react"
import logoSrc from '../../static/logo_text.png'
import withDefaultLayout from '../HOC/defaultLayout'

const Home = () => {
  return( 
    <s.Body>
      <s.TextBox>
        <s.Logo src={logoSrc}/>
        <h2>
          Software solutions.
        </h2>
      </s.TextBox>
    </s.Body>
  )
}

export default withDefaultLayout(Home)

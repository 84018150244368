import styled from 'styled-components'

export const Body = styled.div`
    height: 100%;
    width: 100%;
    background-color: #282828;
    text-align:center;
    overflow: auto;
    height: 100vh;
`

export const TextBox = styled.div`
    position: absolute; 
    color: #FFF;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
`

export const Logo = styled.img`
    height: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    top: 50%;
    left: 50%;
`
import React, { useState } from 'react'
import { Link } from "gatsby"
import * as s from './backToTop.styled'
import upArrow from '../../../../static/sipka_1.png'
import upArrow2 from '../../../../static/sipka_2.png'

const BackToTop = () => {

  const [UpArrow, setUpArrow] = useState(upArrow)
  const [display, setDisplay] = useState<string>("none")

  const scrollUp = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  const scroll = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      setDisplay("block")
    } else {
      setDisplay("none")
    }
  }

  typeof window !== `undefined` ? window.onscroll = () => { scroll() } : null

  return (
    <s.BackToTopContainer onMouseEnter={() => setUpArrow(upArrow2)} src={UpArrow} onMouseLeave={() => setUpArrow(upArrow)} display={display} onClick={() => scrollUp()} />

  )
}

export default BackToTop